import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/Home.vue"),
  },
  {
    path: "/favoris",
    name: "Favoris",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/Favoris.vue"),
  },
  {
    path: "/profil",
    name: "Profil",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/profil_utilisateur/ProfilUser.vue"
      ),
  },
  {
    path: "/homepub",
    name: "Homepub",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/HomePub.vue"),
  },
  {
    path: "/partages",
    name: "Partages",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/partages/ModalPartagesAbonnes.vue"
      ),
  },
  {
    path: "/profilinfos",
    name: "Profilinfos",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/profil_utilisateur/Profilinfos.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
