import Vue from "vue";
import App from "./App.vue";
import router from "./router";


// Require jQuery (Fancybox dependency)
window.$ = window.jQuery = require('jquery');

// Fancybox
const fancybox = require('@fancyapps/fancybox');
// Fancybox Stylesheet
const fancyboxCSS = require('@fancyapps/fancybox/dist/jquery.fancybox.css');

import moment from "moment";
import store from "./store";
import vuetify from '@/plugins/vuetify' 
//icon vuetify
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vuetify2 from 'vuetify/lib'

import bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
//import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
//fichier de config
import { baseUrl_Profil, baseUrl_Image, baseUrl_Video, baseUrl_Audio, baseUrl_Prog_users} from './config';
// Définissez la base URL en tant que propriété globale sur l'instance de Vue
Vue.prototype.$baseUrl_Profil = baseUrl_Profil;
Vue.prototype.$baseUrl_Image = baseUrl_Image;
Vue.prototype.$baseUrl_Video = baseUrl_Video;
Vue.prototype.$baseUrl_Audio = baseUrl_Audio;
Vue.prototype.$baseUrl_Prog_users = baseUrl_Prog_users;


//variable globale pour recuperer le iduser ayant fait la publication
//sur action de clic (pour composant programme)
Vue.prototype.$recupIdUserPublicateur = { value: "" };
//Nom utilisateur connecte
Vue.prototype.$recupIDUser = { value: "1" };

//Recup idpublication apres click sur le bouton les commentaires
//de la publication
Vue.prototype.$recupIdPublicationVG = { value: "" };
Vue.prototype.$recupIdCategPublication = { value: "" };

//Variables globales Filtrage 
Vue.prototype.$recupCategPublication = { value: "" };
Vue.prototype.$recupNomAbonneAFiltrer = { value: "" };



//Variables globales partage
Vue.prototype.$recupCategPublicationPartageVG = { value: "" };
Vue.prototype.$recupTitrePublicationPartageVG = { value: "" };
Vue.prototype.$recupLienPublicationPartageVG = { value: "" };
Vue.prototype.$recupIdUserPartagePubli_quiAEnvoyer = { value: "" };
Vue.prototype.$recupPrenomUserPartagePubli_quiAEnvoyer = { value: "" };
Vue.prototype.$date_LecturePartageUser = { value: "" };
Vue.prototype.$date_LecturePartagePubUser = { value: "" };
Vue.prototype.$date_LecturePartageEventUser = { value: "" };

//Variables globales partage publicite
Vue.prototype.$recupIdUserPartagePub_quiAEnvoyer = { value: "" };
Vue.prototype.$recupPrenomUserPartagePub_quiAEnvoyer= { value: "" };

Vue.prototype.$recupCategPublicitePartageVG = { value: "" };
Vue.prototype.$recupTitrePublicitePartageVG = { value: "" };
Vue.prototype.$recupLienPublicitePartageVG = { value: "" };

//Variables globales partage Event
Vue.prototype.$recupIdUserPartageEvent_quiAEnvoyer = { value: "" };
Vue.prototype.$recupPrenomUserPartageEvent_quiAEnvoyer= { value: "" };

Vue.prototype.$recupCategEventPartageVG = { value: "" };
Vue.prototype.$recupTitreEventPartageVG = { value: "" };
Vue.prototype.$recupLienEventPartageVG = { value: "" };

//recupere idPublicite sur action de clic
Vue.prototype.$recupIdPubliciteVG = { value: "" };
//recupere idAgenda sur action de clic
Vue.prototype.$recupIdEventVG = { value: "" };
//import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";


export const EventBus = new Vue();

Vue.config.productionTip = false;


new Vue({
  router,
  store,
  jQuery,
  vuetify,
  Vuetify2,
  fancybox, 
  fancyboxCSS,
  bootstrap,
  moment,
  //BootstrapVue,
  //VueBottomSheet,
  render: (h) => h(App),
}).$mount("#app");



