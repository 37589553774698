<template>
  <html lang="en">
    <body>
      <!-- partial:index.partial.html -->
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
        />
      </head>
      <body>
        <div class="wrapper">
          <v-carousel cycle height="100%" hide-delimiter-background v-for="login_imgs in login_img" :key="login_imgs.id">
            <v-carousel-item>
              <v-img
                :src="require(`@/../serveur_web/medias_besk/login/login_img/${login_imgs.img1}`)"                
                loading="lazy"
                width="100%"
                alt="image 01"
                title="image 01"
                aspect-ratio="1"
                class="item grey lighten-2"
                style="
                  padding: 0;
                  margin: 0;
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center center;
                "
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-carousel-item>
            <v-carousel-item>
              <v-img
                loading="lazy"
                :src="require(`@/../serveur_web/medias_besk/login/login_img/${login_imgs.img2}`)"
                width="100%"
                alt="image 01"
                title="image 01"
                aspect-ratio="1"
                class="item grey lighten-2"
                style="
                  padding: 0;
                  margin: 0;
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center center;
                "
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-carousel-item>
            <v-carousel-item>
              <v-img
                loading="lazy"
                :src="require(`@/../serveur_web/medias_besk/login/login_img/${login_imgs.img3}`)"
                width="100%"
                alt="image 01"
                title="image 01"
                aspect-ratio="1"
                class="item grey lighten-2"
                style="
                  padding: 0;
                  margin: 0;
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center center;
                "
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-carousel-item>
            <v-carousel-item>
              <v-img
                loading="lazy"
                :src="require(`@/../serveur_web/medias_besk/login/login_img/${login_imgs.img4}`)"
                width="100%"
                alt="image 01"
                title="image 01"
                aspect-ratio="1"
                class="item grey lighten-2"
                style="
                  padding: 0;
                  margin: 0;
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center center;
                "
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-carousel-item>
          </v-carousel>
          <!--<h1 style="padding-top: 20px">Besk</h1>-->
          <p style="margin-top: 20px;">
            Welcome back you've <br />
            been missed!
          </p>
          <form>
            <input type="text" placeholder="Enter username" />
            <input type="password" placeholder="Password" />
            <p class="recover">
              <a href="#">Recover Password</a>
            </p>
          </form>
          <!--<router-link to="/home"><button>Sign in</button></router-link>-->
          <button @click="logger">Sign in</button>
          <p class="or">----- or continue with -----</p>
          <div class="icons">
            <i class="fab fa-google" style="color: red"></i>
            <i class="fab fa-twitter" style="color: blue"></i>
            <i class="fab fa-facebook"></i>
          </div>
          <div class="not-member">
            Not a member? <a href="#">Register Now</a>
          </div>
        </div>
      </body>
      <!-- partial -->
    </body>
  </html>
</template>

<script>
import apiserver from "../apibesk";

export default {
  name: "Login",

  props: {},

  data() {
    return {
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      login_img:[],
    };
  },

  components: {},

  methods: {
    logger() {
      /*Generer les id pour les non authetifiés*/
      var login = "kramo";
      var password = "123456";
      var idUser = "1";
      var vm = this;

      localStorage.setItem("bnews_user", login);
      localStorage.setItem("bnews_pwd", password);
      localStorage.setItem("bnews_id", idUser);

      apiserver
        .get("/serveur_web/gestion_users.php", {
          request: 1,
          //test: this.test,
        })
        .then(function (response) {
          if (response.data == "ok") {
            alert("Bienvenue! Merci d'être BESK");
            //window.location.href = "Home";
            vm.$router.push("/home");
          }
        })
        .catch(function (error) {
          alert("Erreur d'authentification");
          console.log(error);
        });
    },

    allimglogin() {
      var vm = this;
      apiserver
        .post("/serveur_web/gestion_login_img.php", {
          request: "recupImgLogin",
        })
        .then(function (response) {
          vm.login_img = response.data;
          console.log(vm.login_img);        
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    
    /*closeService() {
       this.$emit("fermerService");
    },*/
  },

  created: function () {},

  mounted: function () {
    this.allimglogin();
  },

  beforeDestroy: function () {},

  computed: {},

  watch: {},
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Raleway:400,600,700,800);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
body {
  font-family: "Raleway", Arial, sans-serif;
  background: transparent;
}
.wrapper {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
}
h1 {
  font-size: 2rem;
  color: #07001f;
}
p {
  margin-bottom: 1.7rem;
}
form input {
  width: 85%;
  outline: none;
  border: none;
  background: #dfe9f5;
  padding: 12px 14px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.recover {
  text-align: right;
  font-size: 0.8rem;
  margin: 0.2rem 1.7rem 0 0;
}
.recover a {
  text-decoration: none;
  color: #07001f;
}
button {
  font-size: 1.1rem;
  margin-top: 1rem;
  padding: 8px 0;
  border-radius: 5px;
  outline: none;
  border: none;
  width: 85%;
  background: tomato;
  color: #fff;
  cursor: pointer;
}
button:hover {
  background: rgba(122, 30, 30, 0.767);
}
.or {
  font-size: 0.8rem;
  margin-top: 1.5rem;
}
.icons i {
  color: #07001f;
  padding: 00.8rem 1.5rem;
  border-radius: 10px;
  margin-left: 0.9rem;
  font-size: 1.5rem;
  cursor: pointer;
  border: 2px solid #dfe9f5;
}
.icons i:hover {
  color: #fff !important;
  background: #07001f;
  transition: 1s;
}
.icons i:first-child {
  color: green;
}
.icons i:last-child {
  color: blue;
}
.not-member {
  font-size: 0.8rem;
  margin-top: 1.4rem;
}
.not-member a {
  color: tomato;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
</style>
